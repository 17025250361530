import styled from "@emotion/styled";

const ContainerSpinner = styled.div`
    width: 60%;
    margin: 0 auto 5rem auto;
    padding: 7rem 2rem 5rem 2rem;
    /* border: 8px solid var(--primary); */
    /* -webkit-box-shadow: 0px 2px 12px 3px rgba(0,0,0,0.6);
    -moz-box-shadow: 0px 2px 12px 3px rgba(0,0,0,0.6);
    box-shadow: 0px 2px 12px 3px rgba(0,0,0,0.6); */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media (min-width: 768px){
        width: 50%;
    }

    .loader {
        width: calc(100px - 24px);
        height: 50px;
        position: relative;
        animation: flippx 2s infinite linear;
    }

    .loader:before {
        content: "";
        position: absolute;
        inset: 0;
        margin: auto;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #26eef1;
        transform-origin: -24px 50%;
        animation: spin 1s infinite linear;
    }

    .loader:after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50% , -50%);
        background: var(--primary);
        width: 48px;
        height: 48px;
        border-radius: 50%;
    }

    @keyframes flippx {
        0%, 49% {
            transform: scaleX(1);
        }
        50%, 100% {
            transform: scaleX(-1);
        }
    }

    @keyframes spin {
        100% {
            transform: rotate(360deg);
        }
    }

    .contenedor {
        margin: 8rem auto 5rem auto;
        display: table;
    }

    h1 { 
        position: relative; 
        float: left;
        background: var(--white);
        color: var(--primary);
        font-size: 3rem;
        font-weight: 500;
    }

    h1 span {
        position:absolute;
        right:0;
        width:0;
        background: var(--white);
        border-left: 1px solid #000;
        animation: escribir 5s steps(30) infinite alternate;
    }

    @keyframes escribir {
        from { width: 100% }
        to { width:0 }
    }
`;

const Spinner = () => {
  return (
    <ContainerSpinner>
        <span className="loader"></span>
        <div className="contenedor">
            <h1>Enviando............!<span>&#160;</span></h1>
        </div>
    </ContainerSpinner>
  )
}

export default Spinner
