import * as Yup from "yup";

// Letras y espacios, pueden llevar acentos.
const text = new RegExp(/^[a-zA-ZA-ñ-Ñ\s]{1,40}$/)
// Phone number
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const schemaRegistro = Yup.object().shape({
    evento: Yup.string()
        .oneOf(["Operaciones virtuales de transferencia IMMEX"], ["El evento es obligatorio"])
        .required("El evento es obligatorio"),
    nombre: Yup.string()
        .matches(text, "El nombre no es válido")
        .min(2, "El nombre debe tener al menos 2 caracteres")
        .required("El nombre es obligatorio"),
    paterno: Yup.string()
        .matches(text, "El apellido paterno no es válido")
        .min(2, "El apellido paterno debe tener al menos 2 caracteres")
        .required("El apellido paterno es obligatorio"),
    materno: Yup.string()
        .matches(text, "El apellido materno no es válido")
        .min(2, "El apellido materno debe tener al menos 2 caracteres")
        .required("El apellido materno es obligatorio"),
    company: Yup.string()
        .required("La compañía es obligatoria"),
    email: Yup.string()
        .email("El email no es válido")
        .required("El email es obligatorio"),
    telefono: Yup.string()
        .matches(phoneRegExp, "El teléfono no es válido")
        .min(10, "El teléfono debe tener al menos 10 caracteres")
        .max(10, "El teléfono debe tener máximo 10 caracteres")
        .required("El teléfono es obligatorio"),
    ciudad: Yup.string()
        .oneOf(["Cd. Juárez Chihuahua", "Chihuahua, Chih", "Cd. México", "Guadalajara", "Monterrey", "Puebla", "Hermosillo", "Tijuana", "Mexicali",  "Laredo", "Nogales", "Cd Victoria", "Veracruz", "Delicias"], ["La ciudad es obligatoria"])
        .required("La ciudad es obligatoria"),
});

export { schemaRegistro };