import { Outlet } from 'react-router-dom';
import styled from "@emotion/styled";
import Img1 from '../../assets/img1.jpeg';
import Img2 from '../../assets/img2.jpeg';
import Img3 from '../../assets/img3.jpeg';
import Img5 from '../../assets/img5.jpeg';
import Img6 from '../../assets/img6.jpeg';
import Img7 from '../../assets/img7.jpeg';
import Img8 from '../../assets/img8.jpeg';
import Img9 from '../../assets/img9.jpeg';
import Img12 from '../../assets/img12.jpeg';
import Img13 from '../../assets/img13.jpeg';
import Img14 from '../../assets/img14.jpeg';
import IconoFacebook from '../../assets/facebook.png';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination, Autoplay } from "swiper";

const Header = styled.header`
    width: 100%;
    height: 200px;
`;

const Footer = styled.footer`
    background-color: var(--primary);
    color: var(--white);
    text-align: center;
    width: 100%;

    .info-contacto-servicios{
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        row-gap: 4rem;

        @media (min-width: 768px){
            flex-direction: row;
            row-gap: 0;
        }

        .contacto{
            p{
                margin-bottom: 1rem;
            }
        }
    }

    .redes{

        .icon-face{
            border: 2px solid var(--primary);
            background-color: var(--white);
            display: flex;
            justify-content: center;
            align-items: center;
            width: 3rem;
            height: 3rem;
            padding: 2rem;
            transition: all .3s ease-in-out;
            margin: 0 auto;

            &:hover{
                background-color: #3261c7;
            }

            img{
                width: 3rem;
                height: 3rem;
            }
        }
    }

    .copy{
        background-color: var(--secondary);
        padding: 1rem;
    }
`;

const Servicios = styled.div`
    margin-bottom: 3rem;
    padding-top: 2rem;
    h4{
        margin-bottom: 1.5rem;
        text-align: center;
        font-size: 2rem;

        @media (min-width: 768px){
            font-size: 2.4rem;
            margin-bottom: 3rem;
        }
    }

    .infomacion-servicios{
        display: grid;
        justify-content: center;
        row-gap: 1rem;

        @media (min-width: 768px){
            grid-template-columns: repeat(2, 1fr);
            column-gap: 2rem;
        }

        p{
            text-align: center;
            
            @media (min-width: 768px){
                font-size: 1.8rem;
            }
        }
    }
`;

const LayoutPrincipal = () => {

    const images = [
        { id: 1, path: Img1},
        { id: 2, path: Img2},
        { id: 3, path: Img3},
        { id: 5, path: Img5},
        { id: 6, path: Img6},
        { id: 7, path: Img7},
        { id: 8, path: Img8},
        { id: 9, path: Img9},
        { id: 12, path: Img12},
        { id: 13, path: Img13},
        { id: 14, path: Img14},
    ];

  return (
    <div>
        <Header>
            <Swiper
                slidesPerView={1}
                spaceBetween={10}
                pagination={{
                clickable: true,
                }}
                breakpoints={{
                640: {
                    slidesPerView: 2,
                    spaceBetween: 0,
                },
                768: {
                    slidesPerView: 4,
                    spaceBetween: 0,
                },
                1024: {
                    slidesPerView: 5,
                    spaceBetween: 0,
                },
                }}
                modules={[Pagination, Autoplay]}
                className="mySwiper"
                loop={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
            >
                {images.map((image) => (
                    <SwiperSlide key={image.id}>
                        <img src={image.path} alt="slide"/>
                    </SwiperSlide>
                ))}
            </Swiper>
        </Header>
        <Outlet/>
        <Footer>
          <Servicios className='container info-contacto-servicios'>
            <div>
              <h4>Nuestros servicios</h4>
              <div className='infomacion-servicios'>
                  <p>Agencia Aduanal Mexicana</p>
                  <p>Agencia Aduanal Americana</p>
                  <p>Almacenaje</p>
                  <p>Capacitacion</p>
                  <p>Comercializadoras</p>
                  <p>Logistica</p>
                  <p>forwarder</p>
              </div>
            </div>
            <div className='contacto'>
                <h4>Contacto</h4>
                <p>Telefono: (656) 700-1443</p>
                <p>Móvil: (656) 440-8562</p>
                <p>Correo: capacitacion@puntosynergia.com</p>
            </div>
            <div className="redes">
                <h4>Redes sociales</h4>
                <a href='https://www.facebook.com/people/Punto-Synergia-Log%C3%ADstica-y-Comercio-Internacional/100089231678245/' target="_blank" className='icon-face' rel="noreferrer">
                    <img src={IconoFacebook} alt="icono facebook"/>
                </a>
            </div>
          </Servicios>
          <p className='copy'>Todos los derechos reservados por synergia &copy;</p>
        </Footer>
    </div>
  )
}

export default LayoutPrincipal