import React from 'react'
import styled from "@emotion/styled";

const AlertaStyles = styled.div`
    background-color: #dd0b00b8;
    padding: .6rem;
    color: var(--white);
`

const Alerta = ({children}) => {
  return (
    <AlertaStyles>
        <p>* {''} {children}</p>
    </AlertaStyles>
  )
}

export default Alerta