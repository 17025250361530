import React from 'react'
import styled from "@emotion/styled";
import { Link } from 'react-router-dom';

const ContainerAvisoPrivacidad = styled.div`
    margin-top: 5rem;
    margin-bottom: 5rem;

    h1, h2{
        font-size: 2rem;
        font-weight: 700;
        text-transform: uppercase;
        color: var(--primary);

        @media (min-width: 768px){
          font-size: 2.5rem;
        }
    }

    .informacion{
        margin: 2rem 0;
        line-height: 1.5;
    }

    .mt-2{
        margin-top: 2rem;
    }

    .text-uppercase{
      text-transform: uppercase;
    }
    
    .text-bold{
      font-weight: 700;
    }

    .btn-back{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      
      a{
        border: none;
        border: 1px solid var(--primary);
        background-color: var(--primary);
        color: var(--white);
        padding: 1rem 2rem;
        text-transform: uppercase;
        display: block;
        cursor: pointer;
        transition: all .3s ease;
        text-decoration: none;

        &:hover{
            background-color: var(--white);
            color: var(--primary);
        }
      }
    }
`;

const AvisoPrivacidad = () => {
  return (
    <ContainerAvisoPrivacidad className='container'>
      <h1>Aviso de privacidad</h1>
      <h2>REGISTRO DE DATOS PERSONALES</h2>

      <div className='informacion'>
        <p>
          La empresa Punto Synergia Logistica y Comercio Internacional  con domicilio en Av. Hermanos Escobar No. 5985 Col. San Lorenzo C.P. 3240 es  responsable del tratamiento de los datos personales que nos proporcione, los cuales serán protegidos conforme a lo dispuesto por la Ley de Protección de Datos Personales en Posesión de Sujetos Obligados.
        </p>

        <p className='mt-2 text-uppercase text-bold'>FINALIDADES:</p>
        <p>Sus datos personales serán utilizados para las siguientes finalidades: </p>
        <p>A)	Registrar su inscripción a la modalidad de capacitación</p>
        <p>B)	Generar listas de asistencias y validación de las mismas</p>
        <p>C)	Establecer comunicación para dar seguimiento de los cursos.</p>
        
        <p className='mt-2'>
         De manera adicional, utilizaremos la información personal para las siguientes finalidades que nos permitirán y facilitaran brindarle una mejor atención:
        </p>
        <p>A)	Invitación a futuras capacitaciones o eventos</p>
        <p>B)	Envío de material en caso de que exista</p>

        <p className='mt-2 text-uppercase text-bold'>Ejercicio de Derechos ARCO y otros derechos:</p>
        <p>
          A. Es su derecho a conocer que datos personales se tienen de usted, para que se utilizan y las condiciones del uso que les damos. 
        </p>
        <p>
          B. Es su derecho solicitar la corrección de su información personal en caso de que se encuentre desactualizada, sea inexacta o incompleta
        </p>
        <p>
          D. Es su derecho a limitar el uso y divulgación de sus datos personales y a revocar el consentimiento que haya otorgado para su tratamiento.
        </p>

        <p className='mt-2'>
          En caso de que no desee que sus datos personales sean tratados para las finalidades adicionales, usted puede manifestarlo en el correo electrónico capaciacion@puntosynergia.mx 
        </p>

        <p className='mt-2 text-uppercase text-bold'>TRANSFERENCIA DE DATOS PERSONALES:</p>
        <p>
          Se informa que no se realizaran transferencias que requieran su consentimiento, salvo aquellas que sean necesarias para su requerimiento de información de una autoridad  competente, debidamente fundamentados y motivados, o bien, por lo señalado en el artículo 65 de la Ley de Protección de Datos Personales en Posesión de Sujetos Obligados.
        </p>

        <p className='mt-2 text-uppercase text-bold'>SOLICITUD DE DATOS:</p>
        <p>
          Datos personales recabados serán con la finalidad antes señalada se solicitaran los siguientes datos personales:
        </p>
        <p>A) nombre completo</p>
        <p>C) lugar de residencia</p>
        <p>D) teléfono</p>
        <p>E)	correo electrónico</p>
        <p>F)	Ocupación</p>
        <p>G)	empresa o institución donde labora</p>

        <p className='mt-2 text-uppercase text-bold'>Cambios al Aviso de Privacidad</p>
        <p>
          De realizarse modificaciones sustanciales en el aviso de privacidad, se las comunicaremos a través del medio del correo electrónico que nos ha proporcionado.
        </p>

        <p className='mt-2 text-uppercase text-bold'>Consentimiento</p>
        <p>
          Mientras no manifieste oposición en los términos establecidos en el apartado “Ejercicio de Derechos”, se entenderá que, al proporcionarnos sus Datos Personales, acepta las condiciones del tratamiento descritas en el presente Aviso de Privacidad, de conformidad con la Ley de Protección de Datos Personales en Posesión de Sujetos Obligados.
        </p>
      </div>

      <div className='btn-back'>
        <Link to="/">regresar</Link>
      </div>
    </ContainerAvisoPrivacidad>
  )
}

export default AvisoPrivacidad