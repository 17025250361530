import { useState } from "react";
import styled from "@emotion/styled";
import { Link } from 'react-router-dom';
import { Formik, Form, Field } from "formik";
import { schemaRegistro } from "../utils/schemas/validationSchema";
import Alerta from "../components/ui/Alerta";
import axios from "axios";
import { fecha } from "../helpers/funcionesComun.js";
import Spinner from "../components/spinners/Spinner";
import IconSend from "../assets/iconSend.png";
import Logo from "../assets/logo.jpeg";

const HomeStyles = styled.div`

    .bienvenida-logo{
        padding: 2rem 4rem;
        background-color: #8E0148;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: rgb(142,1,72);
        background: linear-gradient(184deg, rgba(142,1,72,1) 50%, rgba(252,70,107,1) 100%);
        
        @media (min-width: 768px){
            column-gap: 8rem;
            flex-direction: row;
            background: rgb(142,1,72);
            background: linear-gradient(90deg, rgba(142,1,72,1) 50%, rgba(252,70,107,1) 100%);
            justify-content: center;
        }

        img{
            width: 200px;
            height: 200px;
        }

        div{
            padding-top: 4rem;
        }
        
        p{
            color: var(--white);
            font-size: 1.8rem;
            margin-bottom: 1rem;

            @media (min-width: 768px){
                font-size: 2.2rem;
            }
        }

    }

    .titulo-form{
        text-align: center;
        margin: 10rem 0 2rem 0;
        font-size: 2.8rem;
        color: var(--primary);
        font-weight: 700;
    }

    .form{
        margin: 0 auto 9rem auto;
        width: 90%;
        background: rgb(70,8,38);
        background: linear-gradient(135deg, rgba(70,8,38,1) 0%, rgba(135,18,75,1) 100%);
        padding: 3rem;
        border-radius: .5rem;
        -webkit-box-shadow: 0px 2px 12px 3px rgba(0,0,0,0.6);
        -moz-box-shadow: 0px 2px 12px 3px rgba(0,0,0,0.6);
        box-shadow: 0px 2px 12px 3px rgba(0,0,0,0.6);

        @media (min-width: 768px){
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 2rem;
        }

        .campo{
            margin-bottom: 2rem;
        }

        label{
            display: block;
            text-transform: uppercase;
            color: var(--grey);
            font-weight: 700;
            margin-bottom: .5rem;
        }

        input, select{
            width: 100%;
            padding: 1rem;
            outline: none;
            border:none;
            border: 1px solid var(--primary);
            border-radius: .5rem;
        }

        button{
            border: none;
            border: 1px solid var(--primary);
            background-color: var(--secondary);
            color: var(--white);
            padding: 1rem 2rem;
            text-transform: uppercase;
            margin: 3rem auto 0 auto;
            display: block;
            cursor: pointer;
            transition: all .3s ease;
            border-radius: .5rem;

            &:hover{
                background-color: var(--white);
                color: var(--primary);
            }

            @media (min-width: 768px){
                grid-row: 5/6;
                grid-column: 1/3;
            }
        }

        a{
            display: block;
            text-align: center;
            margin-top: 2rem;
            text-decoration: none;
            color: var(--white);

            @media (min-width: 768px){
                grid-row: 6/7;
                grid-column: 1/3;
            }
        }
    }
`;

const Titles = styled.div`
    color: var(--primary);
    width: 90%;
    margin: 5rem auto 5rem auto;
    text-align: center;

    h1{
        font-size: 2.4rem;
        font-weight: 700;

        @media (min-width: 768px){
            font-size: 4rem;
        }
    }
`;

const ContainerSuccess = styled.div`
    width: 90%;
    margin: 5rem auto 9rem auto;
    -webkit-box-shadow: 0px 2px 12px 3px rgba(0,0,0,0.6);
    -moz-box-shadow: 0px 2px 12px 3px rgba(0,0,0,0.6);
    box-shadow: 0px 2px 12px 3px rgba(0,0,0,0.6);
    text-align: center;
    padding: 2rem 5rem 8rem 5rem;

    @media (min-width: 768px){
        width: 50%;
        padding: 2rem 8rem 8rem 8rem;
    }

    h2{
        font-size: 2rem;
        margin-bottom: 2rem;

        @media (min-width: 768px){
            font-size: 2.5rem;
        }
    }

    p{
        font-size: 1.4rem;

        @media (min-width: 768px){
            font-size: 2rem;    
        }
    }

    .icon{
        display: flex;
        justify-content: center;
        margin-bottom: 3rem;
        img{
            width: 200px;
            height: 200px;
        }
    }

    button{
        border: none;
        border: 1px solid #03b41a;
        background-color: #03b41a;
        color: var(--white);
        padding: 1rem 2rem;
        text-transform: uppercase;
        margin: 3rem auto 0 auto;
        border-radius: .5rem;
        transition: all .3s ease;
        cursor: pointer;

        &:hover{
            background-color: var(--white);
            color: #03b41a;
        }
    }
`;

const Home = () => {

    const [loading, setLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    const ciudades = [
        {id: 1, ciudad: 'Cd. Juárez Chihuahua'},
        {id: 2, ciudad: 'Chihuahua, Chih'},
        {id: 3, ciudad: 'Cd. México'},
        {id: 4, ciudad: 'Guadalajara'},
        {id: 5, ciudad: 'Monterrey'},
        {id: 6, ciudad: 'Puebla'},
        {id: 7, ciudad: 'Hermosillo'},
        {id: 8, ciudad: 'Tijuana'},
        {id: 9, ciudad: 'Mexicali'},
        {id: 10, ciudad: 'Laredo'},
        {id: 11, ciudad: 'Nogales'},
        {id: 12, ciudad: 'Cd. Victoria'},
        {id: 12, ciudad: 'Veracruz'},
        {id: 13, ciudad: 'Delicias'}
    ]

    const handleSubmit = async (values) => {
        setLoading(true);
        const datos = {
            evento: values.evento,
            nombre: values.nombre,
            paterno: values.paterno,
            materno: values.materno,
            company: values.company,
            email: values.email,
            telefono: values.telefono,
            ciudad: values.ciudad,
            fecha: fecha()
        }
        try{
            const { data } = await axios.post('https://formsubmit.co/ajax/capacitacion@puntosynergia.com',datos);
            console.log(data);
        }catch(error){
            console.log(error);
        }

        setTimeout(() => {
            setLoading(false);
            setShowSuccess(true);
        }, 5000);
    }

  return (
    <HomeStyles>
        <Titles>
            <h1>Punto Synergia</h1>
        </Titles>
        <div className="bienvenida-logo">
            <img src={Logo} alt="logo"/>
            <div>
                <p>¡Hola! Bienvenido a PUNTO SYNERGIA, tu aliado de confianza.</p>
                <p>¡Gracias por capacitarte con nosotros!, te invitamos a que estes atento a nuestros cursos en este 2023!</p>
            </div>
        </div>
        {
            loading ? (
                <Spinner/>
            ) : (
                (
                    showSuccess ? (
                        <ContainerSuccess>
                            <div className="icon">
                                <img src={IconSend} alt="send"/>
                            </div>
                            <h2>Registro enviado correctamente</h2>
                            <p>Pronto nos contactaremos contigo</p>
                            <button onClick={() => setShowSuccess(false)}>Regresar</button>
                        </ContainerSuccess>
                    ) : (
                        <>
                        <p className="titulo-form">Formulario de registro</p>
                        <Formik
                            initialValues={{
                                evento: '',
                                nombre: '',
                                paterno: '',
                                materno: '',
                                company: '',
                                email: '',
                                telefono: '',
                                ciudad: '',
                            }}
                            onSubmit={(values, {resetForm}) => {
                                handleSubmit(values);
                                resetForm();
                            }}
                            validationSchema={schemaRegistro}
                        >
                            {({errors, touched}) => {
                                return (
                                    <Form className="form container">
                                        <div className='campo'>
                                            <label htmlFor="evento">Evento de registro</label>
                                            <Field 
                                                id='evento'
                                                name='evento'
                                                as='select'
                                            >
                                                <option value="">-- Seleccione --</option>
                                                <option value="Operaciones virtuales de transferencia IMMEX">Operaciones virtuales de transferencia IMMEX</option>
                                            </Field>
                                            {
                                                errors.evento && touched.evento ? (
                                                    <Alerta>{errors.evento}</Alerta>
                                                ) : null
                                            }
                                        </div>
                                        <div className='campo'>
                                            <label htmlFor="nombre">Nombre</label>
                                            <Field 
                                                type="text" 
                                                id="nombre" 
                                                placeholder="Nombre"
                                                name='nombre'
                                            />
                                            {
                                                errors.nombre && touched.nombre ? (
                                                    <Alerta>{errors.nombre}</Alerta>
                                                ) : null
                                            }
                                        </div>
                                        <div className='campo'>
                                            <label htmlFor="paterno">Apellido paterno</label>
                                            <Field 
                                                type="text" 
                                                id="paterno" 
                                                placeholder="Apellido paterno"
                                                name='paterno'
                                            />
                                            {
                                                errors.paterno && touched.paterno ? (
                                                    <Alerta>{errors.paterno}</Alerta>
                                                ) : null
                                            }
                                        </div>
                                        <div className='campo'>
                                            <label htmlFor="materno">Apellido materno</label>
                                            <Field 
                                                type="text" 
                                                id="materno" 
                                                placeholder="Apellido materno"
                                                name='materno'
                                            />
                                            {
                                                errors.materno && touched.materno ? (
                                                    <Alerta>{errors.materno}</Alerta>
                                                ) : null
                                            }
                                        </div>
                                        <div className='campo'>
                                            <label htmlFor="company">Compañia</label>
                                            <Field 
                                                type="text" 
                                                id="company" 
                                                placeholder="Compañia"
                                                name='company'
                                            />
                                            {
                                                errors.company && touched.company ? (
                                                    <Alerta>{errors.company}</Alerta>
                                                ) : null
                                            }
                                        </div>
                                        <div className='campo'>
                                            <label htmlFor="email">Email</label>
                                            <Field 
                                                type="email" 
                                                id="email" 
                                                placeholder="Email"
                                                name='email'
                                            />
                                            {
                                                errors.email && touched.email ? (
                                                    <Alerta>{errors.email}</Alerta>
                                                ) : null
                                            }
                                        </div>
                                        <div className='campo'>
                                            <label htmlFor="telefono">Teléfono</label>
                                            <Field 
                                                type="tel" 
                                                id="telefono" 
                                                placeholder="Teléfono"
                                                name='telefono'
                                            />
                                            {
                                                errors.telefono && touched.telefono ? (
                                                    <Alerta>{errors.telefono}</Alerta>
                                                ) : null
                                            }
                                        </div>
                                        <div className='campo'>
                                            <label htmlFor="ciudad">Ciudad</label>
                                            <Field 
                                                id='ciudad'
                                                name='ciudad'
                                                as='select'
                                            >
                                                <option value="">-- Seleccione --</option>
                                                {
                                                    ciudades.map(ciudad => (
                                                        <option key={ciudad.id} value={ciudad.ciudad}>{ciudad.ciudad}</option>
                                                    ))
                                                }
                                            </Field>
                                            {
                                                errors.ciudad && touched.ciudad ? (
                                                    <Alerta>{errors.ciudad}</Alerta>
                                                ) : null
                                            }
                                        </div>
                                        <button type="submit send">Enviar Registro</button>
                                        <Link className='aviso-privacidad' to='/aviso-privacidad'>Aviso de privacidad</Link>
                                    </Form>
                                )
                            }}
                        </Formik>
                        </>
                    )
                )
            )
        }
    </HomeStyles>
  )
}

export default Home