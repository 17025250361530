import { BrowserRouter, Routes, Route } from "react-router-dom";
import LayoutPrincipal from "./components/layouts/LayoutPrincipal";
import Home from "./pages/Home";
import AvisoPrivacidad from "./pages/AvisoPrivacidad";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LayoutPrincipal />}>
          <Route index element={<Home />} />
          <Route path="aviso-privacidad" element={<AvisoPrivacidad />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
